import React from 'react'
import { BsGithub, BsLinkedin, BsTwitter } from 'react-icons/bs';
const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a href='https://github.com/Kushalmydesk' rel="noreferrer" target='_blank'>
          <BsGithub />
        </a>
      </div>
      <div>
        <a href='https://www.linkedin.com/in/kushal-mondal-dev/' rel="noreferrer" target='_blank'>
          <BsLinkedin />
        </a>
      </div>
      <div>
        <a href='https://twitter.com/kushal99mondal' rel="noreferrer" target='_blank'>
          <BsTwitter />
        </a>
      </div>
    </div>
  )
}

export default SocialMedia
